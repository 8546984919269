.grid {
  position: relative;
}

.grid-item {
  border-color: transparent;
  border-radius: 2px;

  box-shadow: 0 0 3px 2px lightgrey;

  background-color: var(--color-light-gray);
  color: var(--color-charcoal);

  overflow: hidden;
  position: relative;
}

.grid-item.grid-item-clickable:hover {
  transition: filter 0.4s;
  filter: brightness(95%);

  cursor: pointer;
}

.grid-item-handle {
  color: var(--color-charcoal);
  width: 20px;
  height: 20px;
  cursor: move;
  padding-top: 5px;
  padding-left: 5px;
  float: left;

  opacity: 0.2;
  transition: opacity 0.4s;
}

.react-resizable-handle-se {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
}

.react-resizable-handle-se::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-grid-item:hover .grid-item-handle {
  opacity: 1;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-placeholder {
  background: gray;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.grid-item h2 {
  display: block;
  width: 100%;

  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;

  text-align: center;
}

.grid-item h3 {
  font-weight: normal;
  font-size: 13px;
  text-transform: uppercase;

  position: absolute;
  left: 10px;
  bottom: 10px;

  margin-block-end: 0;
  margin-block-start: 0;
}

.histogram-container {
  padding-left: 25px;
}

.traffic-item {
  flex: 30%;
  text-align: center;
}

.histogram-title {
  padding-top: 25px;
}

.grid-item-small .histogram-subtitle {
  display: none;
}

.resizing .histogram-container * {
  display: none;
}
