.tileButton {
  cursor: pointer;
  transition: opacity 0.4s;
  width: fit-content;

  font-size: 1.2em;
}

.tileInfoButton {
  color: var(--color-charcoal);
  opacity: 0.2;
  font-size: 1.2em;
  cursor: pointer;
  transition: opacity 0.4s;

  padding-top: 5px;
  padding-left: 5px;
  float: left;
}

.tileRemoveButton {
  color: var(--color-charcoal);
  opacity: 0.2;
  font-size: 1.2em;
  cursor: pointer;
  transition: opacity 0.4s;

  padding-top: 5px;
  padding-right: 5px;
  float: right;
}

:global .react-grid-item:hover :local .tileInfoButton,
:global .react-grid-item:hover :local .tileRemoveButton {
  opacity: 1;
}
