.nu-dashboard-app {
  width: 900px;

  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-top: 10px;
}

.nu-dashboard-app header {
  padding-bottom: 10px;
}

.nu-dashboard-app header h2 {
  float: left;
}

.nu-dashboard-app footer {
  padding: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes glow {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

span.loading {
  animation: glow 1.5s ease-in-out infinite;
  background: #eee;
  color: transparent;
  cursor: progress;
  display: inline-block;
}

:root {
  --color-teal: #009ca6;
  --color-purple: #7b57cb;
  --color-pink: #d62598;
  --color-orange: #ff8a3d;
  --color-charcoal: #262626;
  --color-gray: #dddddd;
  --color-light-gray: #f2f2f2;

  --body-bg: white;

  --font-family: "Arial, sans-serif";
}

html {
  font-family: var(--font-family);
}

body {
  background-color: var(--body-bg);
}

.bar {
  z-index: 100;
}

.pie-segment:nth-child(4n + 1),
.bar:nth-child(4n + 1) {
  fill: var(--color-teal);
}

.pie-segment:nth-child(4n + 2),
.bar:nth-child(4n + 2) {
  fill: var(--color-purple);
}

.pie-segment:nth-child(4n + 3),
.bar:nth-child(4n + 3) {
  fill: var(--color-pink);
}

.pie-segment:nth-child(4n + 0),
.bar:nth-child(4n + 0) {
  fill: var(--color-orange);
}

/* If the last segment would match the first, make it gray */
.pie-segment:nth-child(4n + 1):last-child {
  fill: var(--color-gray);
}

/* If there's only one segment, make it teal */
.pie-segment:nth-child(4n + 1):last-child:first-child {
  fill: var(--color-teal);
}

.bar-background {
  fill: var(--color-gray);
  z-index: 50;
}

.histogram-line {
  stroke: var(--color-teal);
}

.label-text {
  fill: white;
}

.dropdown-item:active {
  color: var(--color-charcoal);
}
