.title {
  padding-top: 40px;
}

.count {
  width: 100%;

  font-size: 2em;
  font-weight: bold;

  position: absolute;
  bottom: 85px;

  text-align: center;
}

.unit {
  display: block;
  width: 100%;

  font-size: 0.9em;

  text-transform: uppercase;
  text-align: center;

  position: absolute;
  bottom: 60px;
}

.message {
  font-size: 1em;
  font-weight: bold;
  bottom: 95px;
}
