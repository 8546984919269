.trend {
  font-weight: normal;
  font-size: 13px;
  text-transform: uppercase;

  text-align: right;

  color: var(--color-charcoal);

  position: absolute;
  right: 10px;
  bottom: 10px;

  margin-block-end: 0;
  margin-block-start: 0;
}

.icon {
  font-size: 1.2em;
}
