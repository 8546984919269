.toggleControl {
  float: left;
  width: fit-content;
}

.toggleControlTitle {
  font-size: 18pt;
  color: black;
}

.toggleControlTitle:hover {
  text-decoration: none;
  color: var(--color-charcoal);
}

.toggleControlArrow {
  font-size: 16pt;
}

.selectorLock {
  color: var(--color-charcoal);
  margin-top: -0.2em;
}

.dashboardItem:active {
  background-color: var(--color-light-gray) !important;
}

.dashboardItem a {
  color: black;
}

.dashboardItem a:hover {
  color: var(--color-charcoal);
}
